import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import SimpleBanner from 'gatsby-theme-husky/src/components/SimpleBanner';
import TextSection from 'components/TextSection';
import { BundleListingPageTypes } from '@shared/types/umbraco/content/bundleListingPage';
import WideBanner from 'components/WideBanner';
import InfoBanner from 'components/InfoBanner';
import SlimBanner from 'components/SlimBanner';
import ProductCardListWithFilters from 'components/ProductCardList';
import SignUp from 'components/SignUp';
import SectionWithCarousel from 'components/SectionWithCarousel';
import QuestionsCarousel from 'components/SupportPage/QuestionsCarousel';
import QuestionsBlock from 'components/SupportPage/QuestionsBlock';

const elements = {
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Product Listing': ({ properties }, keyId) => (
    <ProductCardListWithFilters key={keyId} {...{ ...properties, isBundles: true }} />
  ),
  'Section with carousel': ({ properties }, keyId) => {
    const { text, card, color, carouselMode, button, waiSettings } = properties;
    const questionCards = card.map((item) => item.properties);

    return (
      <SectionWithCarousel
        key={keyId}
        text={text}
        color={color}
        cardsBlock={
          carouselMode ? (
            <QuestionsCarousel
              questionCards={questionCards}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
            />
          ) : (
            <QuestionsBlock questionCards={questionCards} />
          )
        }
        button={button}
      />
    );
  },
};

const BundleListingPage: FC<BundleListingPageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl },
  data: {
    page: {
      nodes: [
        {
          banner,
          defaultCompositions,
          body,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          seoCanonicalUrl,
          alternateUrls,
          ogImageUrl,
        },
      ],
    },
    bundles,
  },
}) => {
  const { waiSettings, productListingSettings, singupFormBaner, purchaseSettings } =
    defaultCompositions;
  const { isShopify, lang, usePriceSpider, isEanProductId } =
    defaultCompositions?.siteSettings || {};
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.properties?.title;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        seoCanonicalUrl,
        purchaseSettings,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
      }}
    >
      {banner?.length ? <SimpleBanner {...{ ...banner[0].properties, breadCrumbs }} /> : null}
      {body.length ? (
        <BodyRenderer
          bodyData={body}
          bodyStructure={elements}
          bodyItemProps={{
            ...{
              waiSettings,
              products: bundles,
              productListingSettings,
              singupFormBaner,
              isShopify,
              lang,
              usePriceSpider,
              isEanProductId,
            },
          }}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query allUmbracoBundleListingQuery($link: String = "", $bundlesLinks: [String]) {
    page: allUmbracoBundleListing(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          siteSettings {
            isShopify
            lang
          }
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
          }
          productListingSettings {
            showing
          }
          purchaseSettings {
            ...FragmentPurchaseSettings
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
        }
        banner {
          ...FragmentSimpleBanner
        }
        body {
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
          ... on TProductListing {
            ...FragmentProductCardList
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TSlimbanner {
            ...FragmentSlimBanner
          }
          ... on TSectionwithcarousel {
            ...FragmentSectionWithCarousel
          }
        }
      }
    }
    bundles: allBundle(filter: { link: { in: $bundlesLinks } }) {
      nodes {
        useRedirects
        productInfo
        productType
        position
        link
        shopifyId
        skuId
        ean
        bundleProductsQuantity
        tags {
          isFamily
          isLabel
          title
          color {
            label
          }
        }
        title
        bundleProducts {
          link
          quantity
        }
        shortDescription
        cardDescription
        lang
        images: imageForCart {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200, srcSetBreakpoints: [100, 400]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        cardImage {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 250, srcSetBreakpoints: [100, 400]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BundleListingPage;
